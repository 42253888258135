(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/draw-selector-2023/assets/javascripts/compose-draw-title-for-analytics.js') >= 0) return;  svs.modules.push('/components/tipsen/draw-selector-2023/assets/javascripts/compose-draw-title-for-analytics.js');
"use strict";


const {
  composeTitle
} = svs.components.tipsen.drawCard.utils;
const {
  getDisplayName
} = svs.utils.products;
function composeExtraMoneyString() {
  let extraMoney = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  if (extraMoney.isJackpot) {
    return "jackpot:".concat(extraMoney.amount);
  } else if (extraMoney.isGuaranteeJackpot) {
    return "garanti-jackpot:".concat(extraMoney.amount);
  } else if (extraMoney.isExtraMoney) {
    return "extra-pengar:".concat(extraMoney.amount);
  } else if (extraMoney.isRolloverIn) {
    return "rullpott:".concat(extraMoney.amount);
  }
  return '';
}
const composeDrawTitleForAnalytics = function () {
  let draw = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  if (!draw.productId || !draw.drawNumber) {
    return '';
  }
  const productName = getDisplayName(draw.productId);
  const drawNumber = draw.drawNumber;
  const drawTitle = composeTitle({
    drawComment: draw.comment,
    description: draw.description,
    productId: draw.productId,
    betIndex: draw.betIndex,
    regCloseTime: draw.regCloseTime
  });
  const extraMoneySummary = composeExtraMoneyString(draw.extraMoney);
  const productAndDrawString = "".concat(productName, "_").concat(drawTitle, "(#").concat(drawNumber, ")");
  return extraMoneySummary ? "".concat(productAndDrawString, "_").concat(extraMoneySummary) : productAndDrawString;
};
setGlobal('svs.components.tipsen.drawSelector.composeDrawTitleForAnalytics', composeDrawTitleForAnalytics);

 })(window);